import { distinctUntilChanged, filter, forkJoin, map, of, switchMap, take, tap } from 'rxjs';

import { CommonModule, DOCUMENT, ViewportScroller } from '@angular/common';
import { Component, OnInit, afterNextRender, inject } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { setUser } from '@sentry/angular-ivy';

import { MixpanelEventEnum } from '@cgib/shared/enums/mixpanel-event.enum';
import { AsyncLightboxRepository } from '@cgib/ui-shared/repositories/async-lightbox.repository';
import { CookiebotService } from '@cgib/ui-shared/services/cookiebot/cookiebot.service';
import { UserGraphqlService } from '@cgib/ui-shared/services/graphql/graphql-user.service';
import { SyncLightboxService } from '@cgib/ui-shared/services/lightbox/sync-lightbox.service';
import { MixpanelService } from '@cgib/ui-shared/services/mixpanel.service';
import { QuotaServiceV2 } from '@cgib/ui-shared/services/quota-v2.service';
import { SwUpdateService } from '@cgib/ui-shared/services/sw-update.service';
import { UserService } from '@cgib/ui-shared/services/user.service';

import { environment } from '../environments/environment';
import ROUTE_CONFIG from './route-configuration.json';
import { SeoService } from './services/seo.service';
import { SessionService } from './shared/services/session/session.service';

@UntilDestroy()
@Component({
    selector: 'cgib-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    imports: [CommonModule, RouterOutlet]
})
export class AppComponent implements OnInit {
    private readonly document = inject(DOCUMENT);
    private readonly router = inject(Router);
    private readonly userService = inject(UserService);
    private readonly quotaService = inject(QuotaServiceV2);
    private readonly graphqlService = inject(UserGraphqlService);
    private readonly syncLightboxService = inject(SyncLightboxService);
    private readonly translocoService = inject(TranslocoService);
    private readonly lightboxRepository = inject(AsyncLightboxRepository);
    private readonly swUpdateService = inject(SwUpdateService);
    private readonly sessionService = inject(SessionService);

    constructor(
        private readonly viewPort: ViewportScroller,
        private readonly seoService: SeoService,
        private readonly mixpanelService: MixpanelService,
        private readonly cookiebotService: CookiebotService
    ) {
        afterNextRender(() => {
            this.userService
                .isAuthenticated$()
                .pipe(
                    distinctUntilChanged(),
                    switchMap(authenticated => {
                        if (authenticated) {
                            return this.graphqlService.getUserDetails$();
                        } else {
                            return of(undefined);
                        }
                    }),
                    map(details => {
                        if (details) {
                            if (details.userSettings) {
                                this.lightboxRepository.setActiveLightboxId(details.userSettings.activatedLightboxId);
                                return details.userSettings.activatedLightboxId;
                            }
                        } else {
                            this.lightboxRepository.reset();
                        }
                        return undefined;
                    }),
                    switchMap(id => {
                        if (id) {
                            return this.syncLightboxService.fetchLightboxIds$(id);
                        } else {
                            return of({ domes: [], backplates: [], locations: [], scenes: [], backplate_3ds: [] });
                        }
                    }),
                    tap(ids => this.lightboxRepository.updateActiveLightboxIds(ids)),
                    untilDestroyed(this)
                )
                .subscribe();
            this.syncLightboxService.subcribeToLightboxUpdates$().pipe(untilDestroyed(this)).subscribe();
            this.cookiebotService.onServiceReady$
                .pipe(
                    filter(data => !!data),
                    tap(() => {
                        if (this.cookiebotService.cookiebot?.consent) {
                            if (this.cookiebotService.cookiebot?.consent?.marketing) {
                                this.initMarketing();
                            }
                            if (this.cookiebotService.cookiebot.consent.statistics) {
                                this.mixpanelService.init();
                            }
                        }
                    }),
                    untilDestroyed(this)
                )
                .subscribe();

            forkJoin([this.cookiebotService.onServiceReady$, this.userService.isAuthenticated$()])
                .pipe(
                    filter(([ready, authenticated]) => ready && authenticated),
                    take(1),
                    switchMap(() =>
                        forkJoin([
                            this.userService.getUserEmail$().pipe(
                                filter(userInfo => !!userInfo),
                                take(1)
                            ),
                            this.userService.getUserId$().pipe(
                                filter(userInfo => !!userInfo),
                                take(1)
                            ),
                            this.userService.getGravatarUrl$(512).pipe(
                                filter(url => !!url),
                                take(1)
                            ),
                            this.quotaService.getQuota$().pipe(
                                filter(quota => !!quota),
                                take(1)
                            )
                        ])
                    ),
                    tap(([mail, id, gravatarUrl, quota]) => {
                        //this.cookiebotService.cookiebot.submitCustomConsent(true, true, true);
                        this.initMarketing();
                        if (mail && id) {
                            // If a user signes in he consents to mixpanel tracking
                            this.mixpanelService.init();
                            this.mixpanelService.identify(id);
                            this.mixpanelService.setEmail(mail);
                            this.mixpanelService.setSubscription(quota?.activeSubscription?.subscriptionName);
                            setUser({
                                username: id,
                                email: mail
                            });
                        }
                        if (gravatarUrl) {
                            this.mixpanelService.setAvatar(gravatarUrl);
                        }
                    }),
                    untilDestroyed(this)
                )
                .subscribe();
            this.cookiebotService.onServiceReady$
                .pipe(
                    filter(data => !!data),
                    switchMap(() => this.cookiebotService.onLoadCallback$),
                    tap(() => {
                        if (this.cookiebotService.cookiebot.changed) {
                            document.location.reload();
                        }
                    }),
                    untilDestroyed(this)
                )
                .subscribe();
        });
        this.viewPort.setOffset([0, 100]);
        const params = new URLSearchParams(this.document.location.search);
        this.seoService.createLinkForCanonicalURL(
            environment.endpoints.frontend +
                this.document.location.pathname +
                (params.has('page') ? `?page=${params.get('page') ?? 1}` : '')
        );
        this.initLanguage();
    }

    ngOnInit() {
        console.log();
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
            const config = ROUTE_CONFIG.map(
                routeConfig => {
                    if (typeof routeConfig !== 'string') {
                        const val = new RegExp(routeConfig.route).exec(this.router.url);
                        if (val?.length === 1) {
                            return routeConfig;
                        }
                        if ((val?.length ?? 0) > 1) {
                            const replaceKey = Object.keys(val?.groups ?? {})?.[0];
                            if (replaceKey) {
                                const replaceValue = val?.groups?.[replaceKey].toUpperCase();
                                if (replaceValue) {
                                    routeConfig.title = routeConfig.title.replace('%' + replaceKey + '%', replaceValue);
                                    routeConfig.description = routeConfig.description.replace(
                                        '%' + replaceKey + '%',
                                        replaceValue
                                    );
                                }
                            }
                            return routeConfig;
                        }
                        return undefined;
                    }
                    return undefined;
                }
                // We need to have comments in json, because of this we need to omit strings
            ).filter(data => !!data)?.[0] as
                | { title: string; route: string; description: string; image: string; keywords?: string[] }
                | undefined;
            if (config) {
                this.seoService.updateTags({
                    title: config.title,
                    ogDescription: config.description,
                    description: config.description,
                    imageUrl: 'https://' + this.document.location.hostname + this.router.url,
                    keywords: config.keywords?.join(', ')
                });

                const params = new URLSearchParams(this.document.location.search);
                this.seoService.createLinkForCanonicalURL(
                    environment.endpoints.frontend +
                        this.document.location.pathname +
                        (params.has('page') ? `?page=${params.get('page') ?? 1}` : '')
                );
            }
        });
    }
    private initMarketing() {
        if (!this.mixpanelService.hasInitalised) {
            this.mixpanelService.init();
            this.mixpanelService.register({
                stage: environment.production ? 'production' : 'development',
                client: 'cgib-website'
            });
            this.mixpanelService.track(MixpanelEventEnum.LAUNCH);
        }
    }

    private initLanguage() {
        // Initialize session with a previous saved state if available
        this.sessionService
            .getSessionConfiguration$()
            .pipe(take(1))
            .subscribe(config => {
                this.translocoService.setActiveLang(config.language);
            });
    }
}
