<div class="search-bar top-0 flex w-full flex-col bg-gray-700 px-3 pb-2">
    <div class="group mx-auto flex w-full max-w-3xl items-center space-x-3">
        <div class="relative -ml-px w-full">
            <input
                class="block w-full rounded-2xl border-gray-900 bg-gray-900 py-2 pl-9 text-sm text-white placeholder-white placeholder-opacity-50 focus-within:z-10 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                #term
                data-testid="search-term"
                type="text"
                autocomplete="off"
                [placeholder]="'common.search' | transloco"
                [ngModel]="searchTerm"
                (keydown.enter)="searchTermChange.next(term.value); term.blur(); trackInput('Enter', term.value)"
            />
            <svg
                data-testid="search-icon"
                class="absolute top-[1px] h-9 w-9 cursor-pointer p-2 text-white hover:text-blue-500"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                (click)="searchTermChange.next(term.value); term.blur(); trackInput('Icon Click', term.value)"
            >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <circle cx="10" cy="10" r="7" />
                <line x1="21" y1="21" x2="15" y2="15" />
            </svg>

            <!--  -->
            <!-- <mat-select
                    (selectionChange)="setSearchType(typeSelect.value)"
                    #typeSelect
                    data-testid="type-select"
                    [value]="searchType()"
                    class="absolute right-1 top-1 max-w-44"
                >
                    @for (opt of ['locations', 'hdri-maps', 'scenes', '3d-backgrounds']; track opt) {
                        <mat-option [value]="opt">{{ 'common.' + opt | transloco }}</mat-option>
                    }
                </mat-select> -->
            <ng-template #showLoading> <cgib-loading-indicator class="h-6 w-6"></cgib-loading-indicator></ng-template>
        </div>
        <button
            class="whitespace-nowrap"
            cgibButton
            [theme]="BUTTON_THEME.ai"
            [ariaLabel]="'Open Visual Search'"
            [overrideClass]="'max-w-44 h-9'"
            (click)="openVisualSearchInput()"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                class="mr-2 h-5 w-5 cursor-pointer text-white hover:text-blue-500"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                *ngIf="(loading$ | async) === false; else showLoading"
                (click)="openVisualSearchInput()"
            >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path
                    d="M11.5 20h-6.5a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2h1a2 2 0 0 0 2 -2a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1a2 2 0 0 0 2 2h1a2 2 0 0 1 2 2v2.5"
                ></path>
                <path d="M14.757 11.815a3 3 0 1 0 -3.431 4.109"></path>
                <path d="M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
                <path d="M20.2 20.2l1.8 1.8"></path>
            </svg>
            Search by Image
        </button>
    </div>
</div>
