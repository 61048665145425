import {
    EnvironmentProviders,
    NgZone,
    PLATFORM_ID,
    Type,
    inject,
    makeEnvironmentProviders,
    provideAppInitializer
} from '@angular/core';

import { CgibCookiebotConfig } from '../../config/cookiebot.config';
import { cookiebotFactory } from './cookiebot.factory';
import { CookiebotService } from './cookiebot.service';

export function cookiebotProvider(cookiebotConfig: Type<CgibCookiebotConfig>): EnvironmentProviders {
    return makeEnvironmentProviders([
        { provide: CgibCookiebotConfig, useClass: cookiebotConfig },
        {
            provide: CookiebotService,
            useClass: CookiebotService,
            deps: [CgibCookiebotConfig, PLATFORM_ID]
        },
        provideAppInitializer(() => {
            const initializerFn = cookiebotFactory(inject(CookiebotService), inject(NgZone));
            return initializerFn();
        })
    ]);
}
