import { BehaviorSubject, asyncScheduler, fromEvent, tap, throttleTime } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    Renderer2,
    afterNextRender,
    inject
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MixpanelEventEnum } from '@cgib/shared/enums/mixpanel-event.enum';
import { ButtonDirective } from '@cgib/ui-shared/directives/button.directive';
import { ButtonTheme } from '@cgib/ui-shared/enums/button-theme.enum';
import { IndicatorsModule } from '@cgib/ui-shared/modules/indicators/indicators.module';
import { MixpanelModule } from '@cgib/ui-shared/modules/mixpanel/mixpanel.module';
import { MixpanelService } from '@cgib/ui-shared/services/mixpanel.service';
import { SearchInputService } from '@cgib/ui-shared/services/search-input/search-input.service';
import { UploadFileService } from '@cgib/ui-shared/services/upload-file.service';

import { FileSelectPortalService } from '../upload-file-select-portal/file-select-portal.service';
import { FileSelectService } from '../upload-file-select/file-select.service';

function decodeInput(value: string | undefined | null): string | undefined {
    if (!value) {
        return undefined;
    }
    return decodeURI(value);
}
@UntilDestroy()
@Component({
    imports: [
        CommonModule,
        MixpanelModule,
        FormsModule,
        TranslocoModule,
        IndicatorsModule,
        RouterModule,
        MatSelectModule,
        ButtonDirective
    ],
    selector: 'cgib-search-input',
    templateUrl: './search-input.component.html',
    styleUrls: ['./search-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [FileSelectService, FileSelectPortalService, UploadFileService]
})
export class SearchInputComponent {
    NAVIGATION = MixpanelEventEnum.NAVIGATION;
    BUTTON_THEME = ButtonTheme;
    private readonly searchService = inject(SearchInputService);

    @Input({ transform: decodeInput }) searchTerm: string | undefined;

    @Output() searchTermChange = new EventEmitter<string>();
    @Output() errorMessage = new EventEmitter<string | null>();

    loading$ = new BehaviorSubject<boolean>(false);

    searchType = this.searchService.searchType;

    constructor(
        public readonly elementRef: ElementRef<HTMLDivElement>,
        private readonly renderer: Renderer2,
        private readonly mixpanelService: MixpanelService,
        private readonly fileSelectService: FileSelectService,
        private readonly fileSelectServicePortal: FileSelectPortalService
    ) {
        this.fileSelectService.errorMessage$().pipe(
            tap(error => this.errorMessage.emit(error)),
            untilDestroyed(this)
        );
        afterNextRender(() => {
            fromEvent(window, 'scroll')
                .pipe(throttleTime(50, asyncScheduler, { leading: false, trailing: true }), untilDestroyed(this))
                .subscribe(() => {
                    this.handleScrollAppearance();
                });
        });
    }
    trackInput(submitType: string, term: string) {
        this.mixpanelService.track(MixpanelEventEnum.SEARCH, { submitType, term });
    }
    private handleScrollAppearance() {
        const element = this.elementRef.nativeElement;
        const bar = element.getElementsByClassName('search-bar').item(0);
        const logo = element.getElementsByClassName('search-logo').item(0);
        const rect = element.getBoundingClientRect();
        if (rect?.top < 9) {
            this.renderer.setStyle(element, 'height', '46px');
            bar?.classList.add('fixed');
            bar?.classList.add('pt-2');
            logo?.classList.add('block');
            logo?.classList.remove('hidden');
        } else {
            this.renderer.setStyle(element, 'height', 'auto');
            bar?.classList.remove('fixed');
            bar?.classList.remove('pt-2');
            logo?.classList.remove('block');
            logo?.classList.add('hidden');
        }
    }

    openVisualSearchInput() {
        this.fileSelectServicePortal.openVisualSearchInput();
    }

    setSearchType(type: string) {
        this.searchService.searchType.set(type as any);
    }
}
