<footer class="bg-black" aria-labelledby="footer-heading">
    <h2 id="footer-heading" class="sr-only">Footer</h2>
    <div class="bg-gray-700">
        <div class="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
            <div class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
                <div class="text-center" *ngFor="let contact of contacts">
                    <h3 class="mb-1 flex items-center justify-center">
                        <div class="mr-2 h-4 w-4" [ngStyle]="{ 'background-color': contact.color }"></div>
                        <span class="text-lg font-semibold text-gray-50">{{ contact.location }}</span>
                    </h3>

                    <div class="text-sm text-gray-100">{{ contact.email }}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-black">
        <div class="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
            <div class="lg:flex lg:items-center lg:justify-between">
                <div>
                    <h3 class="text-lg font-semibold uppercase tracking-wider text-white">
                        Subscribe to our newsletter
                    </h3>
                    <p class="mt-1 text-sm text-gray-300">
                        The latest news, articles, and resources, sent directly to your inbox.
                    </p>
                </div>
                <a
                    cgibLink
                    [href]="'/newsletter'"
                    [routerLink]="'/newsletter'"
                    [ariaLabel]="'Subscribe to Newsletter'"
                    overrideClass="h-10 w-1/2"
                    routerLinkActive
                    #rla="routerLinkActive"
                    [isActive]="rla.isActive"
                >
                    Subscribe to Newsletter
                </a>
            </div>
        </div>
    </div>
    <div class="mx-auto max-w-7xl px-4 py-12 sm:px-6 lg:px-8">
        <div class="xl:grid xl:grid-cols-3 xl:gap-8">
            <div class="xl:col-span-1">
                <div class="mb-1 flex">
                    <svg class="mr-3 h-7" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 253.5 292.7">
                        <path d="M126.7 237.9L47.4 192v-91.6l79.3-45.9 79.3 45.9V192l-79.3 45.9z" fill="#fff" />
                        <linearGradient
                            id="prefix__a"
                            gradientUnits="userSpaceOnUse"
                            x1="-1033.138"
                            y1="577.651"
                            x2="-1032.336"
                            y2="577.651"
                            gradientTransform="matrix(337.9666 0 0 -337.9666 349187.406 195401.547)"
                        >
                            <stop offset="0" stop-color="#e6e7e8" />
                            <stop offset=".049" stop-color="#e6e7e8" />
                            <stop offset=".948" stop-color="#fff" />
                            <stop offset="1" stop-color="#fff" />
                        </linearGradient>
                        <path
                            d="M126.7 264.2l98-59.8V91.7l-5.3 1.2-92.7 53.5-79.3-45.9-26.2-15.2v117.8l105.5 61.1z"
                            fill="url(#prefix__a)"
                        />
                        <path d="M126.7 237.9v26.2l-105.5-61V85.3l26.2 15.2 79.3 45.9" opacity=".15" fill="#474747" />
                        <path
                            d="M47.4 192.2l79.3 45.9 79.3-45.9v-91.6l-79.3-45.9-79.3 45.9v91.6zM-.1 73.1L126.7-.1l126.8 73.2v146.4l-126.8 73.2L-.1 219.5V73.1z"
                            fill="#007acc"
                        />
                    </svg>
                    <svg class="h-8 pt-1" viewBox="0 0 4531.4 572.2" xml:space="preserve">
                        <path
                            fill="#fff"
                            d="M343.2 271.5h66.7c-19.2 98-95.6 162.9-197.7 162.9C94.4 434.4 0 344.2 0 222.2 0 99.6 94.4 10.7 212.2 10.7c102.2 0 178.5 63.7 197.7 161.7h-66.7c-16.2-63.1-63.1-101.6-131-101.6-83.5 0-146.6 63.1-146.6 151.5 0 87.7 63.1 151.5 146.6 151.5 67.9-.1 114.8-38.6 131-102.3zM865.4 204.8v221.8h-51.1l-4.8-70.3c-28.8 47.5-77.5 77.5-147.2 77.5-122 0-211.6-93.8-211.6-207.3 0-126.8 95.6-215.8 214.6-215.8 88.3 0 172.5 48.7 196.5 146h-69.1c-21-63.1-78.1-85.9-126.2-85.9-85.9 0-150.3 63.1-150.3 155.7 0 78.7 58.9 146.6 146 146.6 72.1 0 118.4-45.7 132.8-108.2H657.5v-60.1h207.9zM1007.3 17.9v408.7h-64.9V17.9h64.9z"
                        />
                        <g>
                            <path d="M1069.4 426.4v-57.7h57.8v57.7h-57.8z" fill="#3ba6dd" />
                        </g>
                        <g>
                            <path
                                fill="#919191"
                                d="M1521.6 314.9c0 66.7-57.7 111.8-148.5 111.8h-183.9V18h177.3c84.7 0 135.2 40.3 135.2 105.2 0 42.7-23.4 78.7-62.5 92 55.9 10.8 82.4 51 82.4 99.7zM1254.1 75.1v120.2h102.2c54.7 0 84.7-25.8 84.7-63.7 0-35.5-29.5-56.5-81.7-56.5h-105.2zm203.8 232c0-36.7-32.5-58.3-84.7-58.3h-119v120.8h116.6c56.4 0 87.1-27.6 87.1-62.5zM1835.3 144.2v282.5h-61.9v-22.2c-24 18.6-54.7 29.5-90.8 29.5-79.3 0-143-66.1-143-148.5s63.7-148.5 143-148.5c36.1 0 66.7 10.8 90.8 29.4v-22.2h61.9zm-61.9 192.4V234.4c-21-30.1-50.5-40.9-81.7-40.9-51.7 0-89.6 41.5-89.6 92s37.9 92 89.6 92c31.2-.1 60.7-10.9 81.7-40.9zM2092.5 323.9h63.7c-10.2 64.9-66.7 110-137.6 110-84.7 0-149.1-64.3-149.1-148.5 0-83.5 64.3-148.5 149.1-148.5 70.9 0 127.4 45.1 137.6 110h-63.7c-7.8-31.9-36.1-53.5-73.9-53.5-52.3 0-86.5 40.9-86.5 92s34.3 92 86.5 92c37.9 0 66.1-21.6 73.9-53.5zM2308.3 305.9l-53.5 54.1v66.7h-62.5V0h62.5v277.7l132.8-133.4h79.3L2351 262l134 164.7h-81.1l-95.6-120.8z"
                            />
                            <path
                                fill="#919191"
                                d="M2761.5 144.2v290.3c0 85.9-58.3 137.6-143 137.6-79.3 0-137.6-46.9-145.4-114.2h63.7c5.4 34.3 35.5 57.7 81.7 57.7 47.5 0 81.1-29.4 81.1-81.1V390c-24 18.6-55.9 29.4-92 29.4-79.3 0-141.8-63.7-141.8-139.4 0-84.1 62.5-143 141.8-143 36.1 0 67.9 10.8 92 29.4v-22.2h61.9zm-61.9 177.9v-87.7c-21-31.3-51.7-43.9-82.9-43.9-52.3 0-88.3 37.3-88.3 89.6 0 43.3 36.1 85.3 88.3 85.3 31.2 0 61.8-12 82.9-43.3zM2981.4 138.2v58.9c-45.1 1.8-79.3 18.6-104.6 51.7v177.9h-62.5V144.2h62.5v38.5c25.9-28.2 61.4-44.5 104.6-44.5zM3283.1 285.5c0 85.9-64.9 148.5-150.3 148.5s-150.3-62.5-150.3-148.5c0-85.3 64.9-148.5 150.3-148.5s150.3 63.1 150.3 148.5zm-62.5 0c0-51.1-36.1-92-87.7-92-51.7 0-87.7 40.9-87.7 92 0 52.3 36.1 92 87.7 92 51.7-.1 87.7-39.7 87.7-92zM3589 144.2v282.5h-61.9v-27.6c-24.6 22.2-55.3 34.9-92 34.9-73.3 0-122.6-52.3-122.6-133.4V144.2h61.9v152.7c0 51.7 28.2 80.5 73.3 80.5 27.6 0 56.5-15.6 79.3-44.5V144.2h62zM3919 269.3v157.5h-62.5V272.9c0-51.7-27.6-79.3-73.3-79.3-27 0-56.5 15-78.7 42.7v190.5H3642V144.2h62.5v25.2c24.6-21 55.3-32.5 91.4-32.5 73.2.1 123.1 50.6 123.1 132.4zM4243.5 0v426.7h-61.9v-22.8c-24 19.2-54.7 30.1-90.8 30.1-79.3 0-143-66.1-143-149.1 0-81.7 63.7-147.8 143-147.8 36.1 0 66.7 10.8 90.8 29.4V0h61.9zm-61.9 336V234.4c-21-30.1-50.5-40.9-81.7-40.9-51.7 0-89.6 41.5-89.6 91.4 0 50.5 37.9 92.6 89.6 92.6 31.2-.1 60.7-10.9 81.7-41.5zM4410 382.8c36.7 0 61.9-12 61.9-34.3 0-16.8-13.8-28.8-37.9-33.1l-62.5-10.8c-43.3-7.2-83.5-28.8-83.5-79.9 0-54.7 47.5-88.3 117.8-88.3 54.7 0 117.8 18.6 123.8 92.6h-57.7c-3-30.7-30.1-42.7-66.1-42.7s-55.9 13.2-55.9 33.1c0 15 10.8 29.4 43.9 34.9l52.9 8.4c42.7 7.8 84.7 26.4 84.7 81.1 0 59.5-52.3 90.2-123.2 90.2-66.7 0-123.8-26.4-131-101l57.7.6c6.6 34.8 32.4 49.2 75.1 49.2z"
                            />
                        </g>
                    </svg>
                </div>
                <p class="mb-4 text-base text-gray-300">Premium HDRi Maps, Environments, and Backplates.</p>
                <div class="flex space-x-6">
                    <a class="text-white" href="https://www.behance.net/cgibackgrounds" target="_blank">
                        <span class="sr-only">Behance</span>
                        <svg
                            class="h-10 w-10"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M3 18v-12h4.5a3 3 0 0 1 0 6a3 3 0 0 1 0 6h-4.5" />
                            <line x1="3" y1="12" x2="7.5" y2="12" />
                            <path d="M14 13h7a3.5 3.5 0 0 0 -7 0v2a3.5 3.5 0 0 0 6.64 1" />
                            <line x1="16" y1="6" x2="19" y2="6" />
                        </svg>
                    </a>
                    <a class="text-white" href="https://www.instagram.com/cgibackgrounds/" target="_blank">
                        <span class="sr-only">Instagram</span>
                        <svg
                            class="h-10 w-10"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        >
                            <path d="M0 0h24v24H0z" stroke="none" />
                            <rect x="4" y="4" width="16" height="16" rx="4" />
                            <circle cx="12" cy="12" r="3" />
                            <path d="M16.5 7.5v.001" />
                        </svg>
                    </a>
                    <a class="text-white" href="https://linkedin.com/company/cgi-backgrounds/" target="_blank">
                        <span class="sr-only">LinkedIn</span>
                        <svg
                            class="h-10 w-10"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        >
                            <path d="M0 0h24v24H0z" stroke="none" />
                            <rect x="4" y="4" width="16" height="16" rx="2" />
                            <path d="M8 11v5M8 8v.01M12 16v-5M16 16v-3a2 2 0 00-4 0" />
                        </svg>
                    </a>
                    <a class="text-white" href="https://x.com/cgibackgrounds_" target="_blank">
                        <span class="sr-only">X</span>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="h-10 w-10 p-1"
                            stroke-width="1.5"
                        >
                            <path d="M4 4l11.733 16h4.267l-11.733 -16z"></path>
                            <path d="M4 20l6.768 -6.768m2.46 -2.46l6.772 -6.772"></path>
                        </svg>
                    </a>
                    <a class="text-white" href="http://youtube.com/channel/UCl6VzrasXrapDRoLospoFOA" target="_blank">
                        <span class="sr-only">Youtube</span>
                        <svg
                            class="h-10 w-10"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        >
                            <path d="M0 0h24v24H0z" stroke="none" />
                            <rect x="3" y="5" width="18" height="14" rx="4" />
                            <path d="M10 9l5 3-5 3z" />
                        </svg>
                    </a>
                </div>
            </div>
            <div class="mt-12 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4 xl:col-span-2 xl:mt-0">
                <ng-container *ngFor="let parent of navigation$ | async">
                    <div>
                        <h3 class="text-sm font-semibold uppercase tracking-wider text-white">
                            {{ parent.sectionName }}
                        </h3>
                        <ul role="list" class="mt-4 space-y-4">
                            <li
                                *ngFor="let item of parent.links; let i = index"
                                [ngClass]="i === 1 ? 'mt-12 md:mt-0' : ''"
                                role="listitem"
                            >
                                <a
                                    [routerLink]="[url]"
                                    [mixpanel]="{
                                        eventName: EVENT_NAVIGATION,
                                        properties: { location: url }
                                    }"
                                    class="text-base text-gray-300 hover:text-gray-200"
                                    *ngVariable="
                                        $any(item.link).cached_url
                                            ? '/' + $any(item.link).cached_url
                                            : $any(item.link).url as url
                                    "
                                    >{{ item.displayName }}</a
                                >
                            </li>
                        </ul>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>

    <div class="py-3">
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <p class="text-sm text-gray-200">&copy; {{ getCurrentYear() }} CGIBackgrounds, Inc. All rights reserved.</p>
        </div>
    </div>
</footer>
