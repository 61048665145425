import { Route } from '@angular/router';

import { UserPermissionEnum } from '@cgib/shared/enums/user-permission.enum';
import { AuthGuard } from '@cgib/ui-shared/modules/auth/auth-guard';
import { TosGuard } from '@cgib/ui-shared/modules/auth/tos-guard';

import { environment } from '../environments/environment';

export const appRoutes: Route[] = [
    {
        path: 'auth',
        loadChildren: () => import('@cgib/ui-shared/modules/auth/auth.routes').then(m => m.authRoutes)
    },
    {
        path: 'home',
        loadComponent: () => import('./pages/home/home.component').then(c => c.HomeComponent)
    },
    {
        path: 'horizon',
        canActivate: [AuthGuard],
        data: {
            requiredScope: UserPermissionEnum.FEATURE_AI_GENERATE,
            allowedSubscriptions: [
                environment.stripe.freelance.monthlySubscription,
                environment.stripe.freelance.yearlySubscription,
                environment.stripe.pro.monthlySubscription,
                environment.stripe.pro.yearlySubscription
            ]
        },
        loadComponent: () => import('./horizon/horizon.component').then(m => m.HorizonComponent)
    },
    {
        path: 'imaginate',
        redirectTo: 'horizon'
    },
    {
        path: 'connect-app',
        canActivate: [TosGuard],
        loadChildren: () => import('./connect-app/connect-app.routes').then(m => m.connectAppRoutes)
    },
    {
        path: 'contact',
        canActivate: [TosGuard],
        loadChildren: () => import('./contact/contact.routes').then(m => m.CONTACT_ROUTES)
    },
    {
        path: 'newsletter',
        canActivate: [TosGuard],
        loadChildren: () => import('./newsletter/newsletter.routes').then(m => m.NEWSLETTER_ROUTES)
    },
    {
        path: 'subscribe',
        canActivate: [TosGuard],
        loadChildren: () => import('./newsletter/newsletter.routes').then(m => m.NEWSLETTER_ROUTES)
    },
    {
        path: 'pricing',
        canActivate: [TosGuard],
        loadChildren: () => import('./pricing/pricing.routes').then(m => m.pricingRoutes)
    },
    {
        path: 'faqs',
        canActivate: [TosGuard],
        loadChildren: () => import('./faq/faq.routes').then(m => m.faqRoutes)
    },
    {
        path: 'subject',
        canActivate: [TosGuard],
        loadChildren: () => import('./search/seo-location-details/subject.routes').then(m => m.SubjectSeoRoutes)
    },
    {
        path: 'asset',
        canActivate: [TosGuard],
        loadChildren: () => import('./search/asset-details/asset.routes').then(m => m.AssetSeoRoutes)
    },
    {
        path: 'scene',
        canActivate: [TosGuard, AuthGuard],
        loadChildren: () => import('./search/scene-details/scene.routes').then(m => m.SceneSeoRoutes),
        data: [{ requiredScope: UserPermissionEnum.SCENE_READ }]
    },
    {
        path: '3d-background',
        canActivate: [TosGuard, AuthGuard],
        loadChildren: () =>
            import('./search/background-3d-details/background-3d.routes').then(m => m.Background3DSeoRoutes),
        data: [{ requiredScope: UserPermissionEnum.BACKGROUND_3D_READ }]
    },
    {
        path: 'search',
        canActivate: [TosGuard],
        loadChildren: () => import('./search/search.routes').then(m => m.SearchRoutes)
    },
    {
        path: 'legal',
        canActivate: [TosGuard],
        loadChildren: () => import('./legal/legal.routes').then(m => m.LegalRoutes)
    },
    {
        path: 'lightboxes',
        canActivate: [AuthGuard, TosGuard],
        loadChildren: () => import('./lightboxes/lightboxes.routes').then(m => m.LIGHTBOX_ROUTES)
    },
    {
        path: 'resolve-lightbox',
        canActivate: [TosGuard],
        loadChildren: () => import('./resolve-lightbox/resolve-lightbox.routes').then(m => m.ResolveLightboxRoutes)
    },
    {
        path: ':lang/posts',
        canActivate: [TosGuard],
        loadChildren: () => import('./posts/posts.routes').then(m => m.postsRouting)
    },
    {
        path: ':lang/blog',
        canActivate: [TosGuard],
        loadChildren: () => import('./pages/blog/blog.routes').then(m => m.blogRoutes)
    },
    {
        path: ':lang/static',
        canActivate: [TosGuard],
        loadChildren: () => import('./static/static.routes').then(m => m.StaticRoutes)
    },
    {
        path: 'posts',
        canActivate: [TosGuard],
        loadChildren: () => import('./posts/posts.routes').then(m => m.postsRouting)
    },
    {
        path: 'static',
        canActivate: [TosGuard],
        loadChildren: () => import('./static/static.routes').then(m => m.StaticRoutes)
    },
    {
        path: 'blog',
        canActivate: [TosGuard],
        loadChildren: () => import('./pages/blog/blog.routes').then(m => m.blogRoutes)
    },
    {
        path: 'profile',
        canActivate: [AuthGuard],
        loadChildren: () => import('./profile/profile.routes').then(m => m.profileRoutes)
    },
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    {
        path: 'not-found',
        pathMatch: 'full',
        canActivate: [TosGuard],
        loadComponent: () => import('./noindex/noindex.component').then(c => c.NoindexComponent)
    },
    {
        path: '**',
        pathMatch: 'full',
        canActivate: [TosGuard],
        loadComponent: () => import('./noindex/noindex.component').then(c => c.NoindexComponent)
    }
];
