<div
    class="absolute rounded-md bg-blue-500 p-2"
    (click)="scrollTop()"
    [mixpanel]="{ eventName: EVENT_NAVIGATION, properties: { location: 'scroll-top' } }"
>
    <div class="flex items-center">
        <svg
            class="mr-1 h-6 w-6 text-white"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
        >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <line x1="12" y1="5" x2="12" y2="19" />
            <line x1="18" y1="11" x2="12" y2="5" />
            <line x1="6" y1="11" x2="12" y2="5" />
        </svg>
        <span class="mr-1 uppercase text-white">Top</span>
    </div>
</div>
