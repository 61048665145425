import { AngularSvgIconModule } from 'angular-svg-icon';
import { APOLLO_NAMED_OPTIONS, APOLLO_OPTIONS, ApolloModule, NamedOptions } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { Amplify } from 'aws-amplify';
import { ClientOptions, createClient } from 'graphql-ws';
import { enableMapSet } from 'immer';
import { RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha-2';
import NodeWebSocket from 'ws';

import { OverlayModule } from '@angular/cdk/overlay';
import { isPlatformServer } from '@angular/common';
import { provideHttpClient, withFetch } from '@angular/common/http';
import {
    ApplicationConfig,
    ErrorHandler,
    InjectionToken,
    PLATFORM_ID,
    TransferState,
    importProvidersFrom,
    inject,
    isDevMode,
    makeStateKey,
    provideAppInitializer
} from '@angular/core';
import { provideClientHydration, withHttpTransferCacheOptions } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
    Router,
    provideRouter,
    withComponentInputBinding,
    withInMemoryScrolling,
    withViewTransitions
} from '@angular/router';
import { ServiceWorkerModule, provideServiceWorker } from '@angular/service-worker';
import { InMemoryCache } from '@apollo/client/cache';
import { ApolloLink, concat, split } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition, offsetLimitPagination } from '@apollo/client/utilities';
import { provideTransloco } from '@jsverse/transloco';
import { provideTranslocoLocale } from '@jsverse/transloco-locale';
import {
    BrowserTracing,
    EventHint,
    TraceService,
    captureException,
    createErrorHandler,
    init,
    replayIntegration
} from '@sentry/angular-ivy';

import { CgibCookiebotConfig } from '@cgib/ui-shared/config/cookiebot.config';
import { STRIPE_CONFIG } from '@cgib/ui-shared/directives/has-permission.directive';
import { COUNTRIES_TO_HIDE, HIDE_ON_HOST } from '@cgib/ui-shared/directives/host-hide.directive';
import { LightboxFilterModule } from '@cgib/ui-shared/filter/lightbox-item-filter/lightbox-item-filter.module';
import { MIXPANEL_CONFIG, MixpanelModule } from '@cgib/ui-shared/modules/mixpanel/mixpanel.module';
import { MixpanelService } from '@cgib/ui-shared/modules/mixpanel/mixpanel.service';
import { AI_IMAGE_URL_CONFIG } from '@cgib/ui-shared/modules/resolve-ai-image-url/resolve-ai-image-url.pipe';
import { StoryBlokImageUrlModule } from '@cgib/ui-shared/modules/storyblok-image-url/storyblok-image-url.module';
import {
    BACKGROUND_3D_URL_CONFIG,
    Resolve3dBackgroundUrlPipe
} from '@cgib/ui-shared/pipes/resolve-3d-background-url/resolve-3d-background-url.pipe';
import { IMAGE_URL_CONFIG, ResolveImageUrlPipe } from '@cgib/ui-shared/pipes/resolve-image-url/resolve-image-url.pipe';
import { ResolveSceneUrlPipe, SCENE_URL_CONFIG } from '@cgib/ui-shared/pipes/resolve-scene-url/resolve-scene-url.pipe';
import { AuthService } from '@cgib/ui-shared/services/auth.service';
import { cookiebotFactory } from '@cgib/ui-shared/services/cookiebot/cookiebot.factory';
import { CookiebotService } from '@cgib/ui-shared/services/cookiebot/cookiebot.service';
import {
    GRAPHQL_STORYBLOK_CONFIG,
    StoryblokGraphqlConfig
} from '@cgib/ui-shared/services/storyblok-graphql/stroyblok-graphql.service';
import { SCENE_VIDEO_URL_CONFIG } from '@cgib/ui-shared/tokens/asset.token';
import { GRAPHQL_CONFIG } from '@cgib/ui-shared/tokens/graphql.token';
import { STAGE } from '@cgib/ui-shared/tokens/ui.token';

import { environment } from '../environments/environment';
import { appRoutes } from './app.routes';
import { CookiebotConfig } from './cookiebot.config';
import { TranslocoHttpLoader } from './transloco-loader';

enableMapSet();
init({
    dsn: 'https://b32033ea2a9c8606240e47e9d40375e9@o4506525439950848.ingest.sentry.io/4506525441392640',
    environment: isDevMode() ? 'development' : 'production',
    integrations: [
        // using browserTracingIntegration() causes the SSR page to load for 10s
        new BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [/^https:\/\/www(-(dev|test))?\.cgibackgrounds\.com/]
        }),
        replayIntegration({
            maskAllText: false,
            blockAllMedia: false
        })
    ],
    initialScope: {
        tags: { project: 'cgib-pwa' }
    },
    // Performance Monitoring
    tracesSampleRate: globalThis.document?.location?.hostname.includes('localhost') ? 0.0 : 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
    beforeSend: (event: any, _hint: EventHint) => {
        if (globalThis.document?.location?.hostname.includes('localhost')) {
            return null;
        }
        if (
            !/Googlebot|HeadlessChrome|googlebot|adsbot-google|Feedfetcher-Google|bingbot|yandex|baiduspider|Facebot|facebookexternalhit|twitterbot|rogerbot|linkedinbot|embedly|quora link preview|showyoubot|outbrain|pinterest|slackbot|vkShare|W3C_Validator|redditbot|applebot|whatsapp|flipboard|tumblr|bitlybot|skypeuripreview|nuzzel|discordbot|google page speed|qwantify|pinterestbot|bitrix link preview|xing-contenttabreceiver|chrome-lighthouse|telegrambot/.test(
                globalThis.navigator?.userAgent
            )
        ) {
            return event;
        }
        return null;
    },
    ignoreErrors: [
        // Random plugins/extensions
        'top.GLOBALS',
        // Facebook borked
        'fb_xd_fragment',
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        "Can't find variable: ZiteReader",
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',

        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
        // reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        'conduitPage',

        // Network errors such as going offline or being blocked by a proxy
        'Failed to fetch',
        // Instagramm Error: https://developers.facebook.com/community/threads/320013549791141/
        "Can't find variable: _AutofillCallbackHandler",

        // Errors when user makes wrong login attempts those are valid, but do not need to be tracked
        'Invalid code provided, please request a code again.',
        'Password does not conform to policy: Password must have symbol characters',
        'Incorrect username or password.',
        'Token is expired',
        'Username can not be empty',
        'confirmationCode is required to confirmResetPassword',

        // Error on User side:
        'NetworkError when attempting to fetch resource.'
    ],
    denyUrls: [
        // Firefox extensions
        /^resource:\/\//i,
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        /^chrome-extension:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
    ]
});
const APOLLO_CACHE = new InjectionToken<InMemoryCache>('apollo-cache');
const STATE_KEY = makeStateKey<any>('apollo.state');
Amplify.configure({
    Auth: {
        Cognito: {
            userPoolClientId: environment.cognito.userPoolWebClientId,
            userPoolId: environment.cognito.userPoolId,
            loginWith: {
                oauth: {
                    domain: environment.endpoints.hostedUi,
                    scopes: ['openid', 'email', 'aws.cognito.signin.user.admin'],
                    responseType: 'code',
                    redirectSignIn: [
                        globalThis.document?.location.protocol +
                            '//' +
                            globalThis.document?.location?.hostname +
                            (globalThis.document?.location.port ? ':' + globalThis.document?.location.port : '') +
                            '/home'
                    ],
                    //redirectSignIn: ['http://localhost:8888' + '/home'],
                    redirectSignOut: [environment.endpoints.frontend + '/logout']
                }
            }
        }
    }
});
console.log(
    globalThis.document?.location.protocol +
        '//' +
        globalThis.document?.location?.hostname +
        (globalThis.document?.location.port ? ':' + globalThis.document?.location.port : '') +
        '/home'
);
const errorLink = onError(({ operation, /*response,*/ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach((/*{ message, locations, path }*/) => {
            captureException(new Error(operation.operationName));
        });
    }
    if (networkError) {
        captureException(new Error(operation.operationName));
    }
});

export const appConfig: ApplicationConfig = {
    providers: [
        provideClientHydration(
            withHttpTransferCacheOptions({
                includePostRequests: true,
                filter: req => {
                    console.log('test', req.url, !req.url.includes('gapi.storyblok.com'));
                    return !req.url.includes('gapi.storyblok.com');
                }
            })
        ),
        provideHttpClient(withFetch()), // withInterceptors([headerInterceptor])),
        provideRouter(
            appRoutes,
            withViewTransitions({
                onViewTransitionCreated: ({ transition }) => {
                    const router = inject(Router);
                    const targetUrl = router.getCurrentNavigation()?.finalUrl;
                    // Skip the transition if the only thing
                    // changing is the fragment and queryParams

                    if (
                        targetUrl &&
                        router.isActive(targetUrl, {
                            paths: 'exact',
                            matrixParams: 'exact',
                            fragment: 'ignored',
                            queryParams: 'ignored'
                        })
                    ) {
                        transition.skipTransition();
                    }
                }
            }),
            withComponentInputBinding(),
            withInMemoryScrolling({ scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled' })
        ),
        provideAnimations(),
        provideTransloco({
            config: {
                availableLangs: ['en', 'de'],
                defaultLang: 'en',
                // Remove this option if your application doesn't support changing language in runtime.
                reRenderOnLangChange: true,
                prodMode: !isDevMode(),
                fallbackLang: 'en',
                missingHandler: {
                    useFallbackTranslation: true
                }
            },
            loader: TranslocoHttpLoader
        }),
        provideTranslocoLocale({
            langToLocaleMapping: {
                en: 'en-US',
                de: 'de-DE'
            }
        }),
        importProvidersFrom([
            ApolloModule,
            ServiceWorkerModule.register('sw.js', { enabled: !!globalThis.document }),
            OverlayModule,
            AngularSvgIconModule.forRoot(),
            MixpanelModule.forRoot({
                token: environment.mixpanel.projectToken,
                env: environment.production ? 'production' : 'development'
            }),
            StoryBlokImageUrlModule.forRoot(),
            LightboxFilterModule
        ]),
        {
            provide: ErrorHandler,
            useValue: createErrorHandler({
                showDialog: false
            })
        },
        {
            provide: TraceService,
            deps: [Router]
        },
        { provide: STRIPE_CONFIG, useValue: environment.stripe },
        { provide: STAGE, useValue: environment.production ? 'production' : 'development' },
        {
            provide: HIDE_ON_HOST,
            useValue: [environment.endpoints.cnUiHost, environment.endpoints.krUiHost, 'localhost:8887']
        },
        { provide: COUNTRIES_TO_HIDE, useValue: ['CN', 'KR'] },
        {
            provide: MIXPANEL_CONFIG,
            useValue: {
                token: environment.mixpanel.projectToken,
                ip: false,
                env: environment.production ? 'production' : 'development'
            }
        },
        {
            provide: AI_IMAGE_URL_CONFIG,
            useValue: {
                baseUrl: environment.endpoints.aiStatic,
                adoptToDevicePixelRation: true
            }
        },
        {
            provide: IMAGE_URL_CONFIG,
            useValue: {
                baseUrl: environment.endpoints.static,
                adoptToDevicePixelRation: true
            }
        },
        {
            provide: SCENE_URL_CONFIG,
            useValue: {
                baseUrl: environment.endpoints.sceneStatic,
                adoptToDevicePixelRation: true
            }
        },
        {
            provide: BACKGROUND_3D_URL_CONFIG,
            useValue: {
                baseUrl: environment.endpoints.background3dStatic,
                adoptToDevicePixelRation: true
            }
        },
        {
            provide: SCENE_VIDEO_URL_CONFIG,
            useValue: {
                baseUrl: environment.endpoints.sceneVideo
            }
        },
        {
            provide: APOLLO_CACHE,
            useValue: new InMemoryCache({
                typePolicies: {
                    Query: {
                        fields: {
                            aiJobs: offsetLimitPagination()
                            //getPaginatedLightboxDetails: offsetLimitPagination(),
                            //getPaginatedLightboxItems: offsetLimitPagination(['id', 'lightboxType', 'viewMode'])
                        }
                    }
                }
            })
        },
        {
            provide: APOLLO_OPTIONS,
            useFactory: (
                cache: InMemoryCache,
                httpLink: HttpLink,
                authService: AuthService,
                mixpanel: MixpanelService,
                platformId: object,
                transferState: TransferState
            ) => {
                const http = httpLink.create({
                    uri: environment.endpoints.graphql
                });

                const clientOptions: ClientOptions = {
                    url: environment.endpoints.subscription,
                    connectionParams: async () => {
                        const token = await authService.getAccessToken();
                        if (token) {
                            mixpanel.init();
                            return {
                                authorization: 'Bearer ' + token,
                                'x-client-id': environment.cgibClientId,
                                'x-mixpanel-id': mixpanel.getDistinctId()
                            };
                        } else {
                            return {
                                'x-client-id': environment.cgibClientId
                            };
                        }
                    },
                    keepAlive: 40000
                };
                if (isPlatformServer(platformId)) {
                    clientOptions.webSocketImpl = NodeWebSocket;
                }
                const isBrowser = transferState.hasKey<any>(STATE_KEY);

                if (isBrowser) {
                    const state = transferState.get<any>(STATE_KEY, null);
                    cache.restore(state);
                } else {
                    transferState.onSerialize(STATE_KEY, () => {
                        return cache.extract();
                    });
                    // Reset cache after extraction to avoid sharing between requests
                    cache.reset();
                }

                // using the ability to split links, you can send data to each link
                // depending on what kind of operation is being sent

                const link = split(
                    // split based on operation type
                    ({ query }) => {
                        const call = getMainDefinition(query);
                        return call.kind === 'OperationDefinition' && call.operation === 'subscription';
                    },
                    new GraphQLWsLink(createClient(clientOptions)),
                    http
                );
                return {
                    cache,
                    link: errorLink.concat(link),
                    connectToDevTools: false,
                    ssrMode: true,
                    //Required for making network-only request https://github.com/kamilkisiela/apollo-angular/issues/503
                    ssrForceFetchDelay: 1
                };
            },
            deps: [APOLLO_CACHE, HttpLink, AuthService, MixpanelService, PLATFORM_ID, TransferState]
        },
        {
            provide: APOLLO_NAMED_OPTIONS,
            useFactory: (cache: InMemoryCache, httpLink: HttpLink): NamedOptions => {
                return {
                    storyblok: {
                        cache: cache,
                        link: concat(
                            new ApolloLink((operation, forward) => {
                                operation.setContext(({ headers = {} }) => ({
                                    headers: {
                                        ...headers,
                                        token: environment.storyblok.accessToken,
                                        version: environment.stage === 'development' ? 'draft' : 'published'
                                    }
                                }));
                                return forward(operation);
                            }),
                            httpLink.create({
                                uri: environment.endpoints.storyblok
                            })
                        ),
                        connectToDevTools: false,
                        ssrMode: true,
                        ssrForceFetchDelay: 1
                    }
                };
            },
            deps: [APOLLO_CACHE, HttpLink, TransferState]
        },
        provideAppInitializer(() => {
            const initializerFn = (
                (authService: AuthService) => () =>
                    authService.updateAuth()
            )(inject(AuthService));
            return initializerFn();
        }),
        { provide: CgibCookiebotConfig, useClass: CookiebotConfig },
        { provide: ResolveImageUrlPipe, useClass: ResolveImageUrlPipe, deps: [IMAGE_URL_CONFIG] },
        { provide: Resolve3dBackgroundUrlPipe, useClass: Resolve3dBackgroundUrlPipe, deps: [BACKGROUND_3D_URL_CONFIG] },
        { provide: ResolveSceneUrlPipe, useClass: ResolveSceneUrlPipe, deps: [SCENE_URL_CONFIG] },
        { provide: CookiebotService, useClass: CookiebotService, deps: [CgibCookiebotConfig, PLATFORM_ID] },
        provideAppInitializer(() => {
            const initializerFn = cookiebotFactory(inject(CookiebotService));
            return initializerFn();
        }),
        {
            provide: GRAPHQL_STORYBLOK_CONFIG,
            useValue: { token: environment.storyblok.accessToken } as StoryblokGraphqlConfig
        },
        { provide: GRAPHQL_CONFIG, useValue: { cgibClientId: environment.cgibClientId } },
        { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptchaV3SiteKey },
        provideServiceWorker('sw.js', {
            // enabled: isDevMode(),
            registrationStrategy: 'registerWhenStable:9000'
        })
    ]
};
