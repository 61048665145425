import { asyncScheduler, debounceTime, fromEvent, tap } from 'rxjs';

import { MediaMatcher } from '@angular/cdk/layout';
import { CommonModule, NgOptimizedImage, isPlatformBrowser } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    OnInit,
    PLATFORM_ID,
    ViewChild,
    afterNextRender,
    inject,
    signal
} from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { environment } from '../../../../environments/environment';
import { HeaderSearchComponent } from '../header-search/header-search.component';

@UntilDestroy()
@Component({
    imports: [CommonModule, HeaderSearchComponent, TranslocoModule, NgOptimizedImage],
    selector: 'cgib-header-teaser',
    templateUrl: './header-teaser.component.html',
    styleUrls: ['./header-teaser.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderTeaserComponent implements AfterViewInit, OnInit {
    ENVIRONMENT = environment;

    private readonly mediaMatcher = inject(MediaMatcher);
    private readonly platformId = inject(PLATFORM_ID);

    isMobileHeader = signal(true);

    @ViewChild('videoContainer')
    container: ElementRef<HTMLDivElement> | undefined;

    constructor(private readonly elementRef: ElementRef<HTMLDivElement>) {
        afterNextRender(() => {
            fromEvent(window, 'resize')
                .pipe(
                    debounceTime(300, asyncScheduler),
                    tap(async () => {
                        if (!this.isMobileHeader()) {
                            await this.initializeVideo();
                        }
                    }),
                    untilDestroyed(this)
                )
                .subscribe();
        });
    }
    ngOnInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            const matcher = this.mediaMatcher.matchMedia('(max-width: 1120px)');
            matcher.addEventListener('change', event => this.mediaMatchListener(event));
            this.isMobileHeader.set(matcher.matches);
        }
    }
    mediaMatchListener(event: MediaQueryListEvent) {
        this.isMobileHeader.set(event.matches);
    }
    async ngAfterViewInit() {
        if (!this.isMobileHeader()) {
            this.initializeVideo();
        }
    }
    getVideoName(): string | undefined {
        const container = this.container?.nativeElement;

        if (container && container.clientWidth > 1000) {
            return environment.endpoints.staticAssets + 'website/videos/desktop/teaser.mp4';
        } else if (container && container.clientWidth > 500) {
            return environment.endpoints.staticAssets + 'website/videos/tablet/teaser.mp4';
        }
        return undefined;
    }
    async initializeVideo(): Promise<void> {
        const video = this.elementRef.nativeElement.querySelectorAll('video')?.item(0);
        const videoName = this.getVideoName();
        if (videoName && video) {
            video.src = videoName;
            video.muted = true;
            try {
                return video?.play();
            } catch {
                // Do nothing
            }

            return void 0;
        }
    }
}
