<header class="z-[100] bg-gray-700" #header>
    <ng-container *ngFor="let banner of (banner$ | async) ?? []">
        <cgib-banner-header
            [_editable]="banner._editable!"
            [_uid]="banner._uid!"
            [name]="$any(banner).name"
            [description]="$any(banner).description"
            [backgroundColor]="$any(banner).backgroundColor"
            [link]="$any(banner).link"
            [title]="$any(banner).title"
            [endDate]="$any(banner).endDate"
            [startDate]="$any(banner).startDate"
            class="relative h-full w-full"
        ></cgib-banner-header>
    </ng-container>
    <div class="flex min-h-[52px] px-3 py-3">
        <div class="flex flex-1 items-center justify-start space-x-4">
            <button
                class="rounded-md border-transparent ring-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-0"
                type="button"
                aria-label="Open the Menu"
                (click)="openMenu(menuTemplate)"
            >
                <svg
                    class="h-6 w-6 text-white hover:text-gray-200"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx="5" cy="5" r="1" />
                    <circle cx="12" cy="5" r="1" />
                    <circle cx="19" cy="5" r="1" />
                    <circle cx="5" cy="12" r="1" />
                    <circle cx="12" cy="12" r="1" />
                    <circle cx="19" cy="12" r="1" />
                    <circle cx="5" cy="19" r="1" />
                    <circle cx="12" cy="19" r="1" />
                    <circle cx="19" cy="19" r="1" />
                </svg>
            </button>
            <a
                [routerLink]="['/']"
                role="button"
                aria-label="Go to the main page"
                class="flex items-center overflow-hidden rounded-xl bg-gray-700 text-white hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-700"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <polyline points="5 12 3 12 12 3 21 12 19 12" />
                    <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />
                    <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" />
                </svg>
            </a>

            <a
                [routerLink]="['/contact']"
                [routerLinkActive]="'text-blue-500'"
                aria-label="Get in touch with us"
                [queryParams]="{}"
                [mixpanel]="{ eventName: EVENT_NAVIGATION, properties: { location: '/contact' } }"
                class="hidden rounded-xl bg-gray-700 text-sm text-white hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-700 lg:block"
                >{{ 'common.contact' | transloco }}</a
            >
            <a
                *cgibHostHide="'ALL'"
                [routerLink]="['/pricing']"
                [routerLinkActive]="'text-blue-500'"
                aria-label="Check out our plans and pricings"
                [queryParams]="{}"
                [mixpanel]="{ eventName: EVENT_NAVIGATION, properties: { location: '/pricing' } }"
                class="hidden rounded-xl bg-gray-700 text-sm text-white hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-700 lg:block"
                >{{ 'common.pricing' | transloco }}</a
            >
            <a
                *cgibHostHide="'ALL'"
                [routerLink]="['/pricing']"
                [routerLinkActive]="'text-blue-500'"
                aria-label="Check out our plans and pricings"
                [queryParams]="{}"
                [mixpanel]="{ eventName: EVENT_NAVIGATION, properties: { location: '/pricing' } }"
                class="hidden rounded-xl bg-gray-700 text-sm text-white hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-700 sm:block md:block lg:hidden"
                >Pricing</a
            >
        </div>
        <div class="flex flex-1 items-center justify-center px-2 md:px-4">
            <a
                class="flex h-6 items-center justify-center space-x-2 rounded-xl bg-gray-700 px-3 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-700"
                [routerLink]="['/']"
                aria-label="Go to the landing page"
                role="button"
                data-testid="home"
            >
                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 253.5 292.7">
                    <path d="M126.7 237.9L47.4 192v-91.6l79.3-45.9 79.3 45.9V192l-79.3 45.9z" fill="#fff" />
                    <linearGradient
                        id="prefix__a"
                        gradientUnits="userSpaceOnUse"
                        x1="-1033.138"
                        y1="577.651"
                        x2="-1032.336"
                        y2="577.651"
                        gradientTransform="matrix(337.9666 0 0 -337.9666 349187.406 195401.547)"
                    >
                        <stop offset="0" stop-color="#e6e7e8" />
                        <stop offset=".049" stop-color="#e6e7e8" />
                        <stop offset=".948" stop-color="#fff" />
                        <stop offset="1" stop-color="#fff" />
                    </linearGradient>
                    <path
                        d="M126.7 264.2l98-59.8V91.7l-5.3 1.2-92.7 53.5-79.3-45.9-26.2-15.2v117.8l105.5 61.1z"
                        fill="url(#prefix__a)"
                    />
                    <path d="M126.7 237.9v26.2l-105.5-61V85.3l26.2 15.2 79.3 45.9" opacity=".15" fill="#474747" />
                    <path
                        d="M47.4 192.2l79.3 45.9 79.3-45.9v-91.6l-79.3-45.9-79.3 45.9v91.6zM-.1 73.1L126.7-.1l126.8 73.2v146.4l-126.8 73.2L-.1 219.5V73.1z"
                        fill="#007acc"
                    />
                </svg>
                <img class="mt-1 hidden md:block" src="../../../../../assets/logo.svg" [width]="200" [height]="20" />
            </a>
        </div>
        <div class="flex flex-1 items-center justify-end space-x-2">
            <div class="flex flex-1 items-center justify-end space-x-4">
                <a
                    [routerLink]="['/blog']"
                    [routerLinkActive]="'ring-blue-500 ring-2'"
                    aria-label="Check out our blog"
                    data-testid="blog"
                    [queryParams]="{}"
                    [mixpanel]="{ eventName: EVENT_NAVIGATION, properties: { location: '/blog' } }"
                    class="hidden rounded-xl bg-gray-700 px-2 py-1 text-sm text-white hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-700 xl:block"
                    >{{ 'common.blog' | transloco }}</a
                >
                <a
                    [routerLinkActive]="'ring-blue-500 ring-2'"
                    data-testid="lightbox-link"
                    [routerLink]="['/lightboxes']"
                    [queryParams]="{ page: 1 }"
                    class="hidden rounded-xl bg-gray-700 px-2 py-1 text-sm text-white hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-700 lg:block"
                    [mixpanel]="{ eventName: EVENT_NAVIGATION, properties: { location: '/lightboxes' } }"
                    >{{ 'common.lightboxes' | transloco }}</a
                >
            </div>
            <ng-container *ngIf="isAuthenticated$ | async as authenticated; else signIn">
                <ng-container *ngIf="gravatarUrl$ | async as url; else placeHolder">
                    <button
                        aria-label="Open User Menu"
                        class="block h-7 w-7 cursor-pointer overflow-hidden rounded-xl border-2 border-gray-500"
                        [matMenuTriggerFor]="menu"
                        data-testid="user-menu-trigger"
                    >
                        <img
                            class="h-full w-full border-0 object-cover"
                            [src]="url"
                            [alt]="'image showing the profile picture of the logged in person'"
                        />
                    </button>
                    <mat-menu class="bg-gray-600 px-3" #menu="matMenu">
                        <button
                            data-testid="profile-menu-item"
                            class="!flex w-full !flex-row items-center hover:bg-gray-700"
                            role="menuitem"
                            mat-menu-item
                            [routerLink]="['/profile']"
                            [mixpanel]="{
                                eventName: EVENT_NAVIGATION,
                                properties: { location: '/profile' }
                            }"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="mr-2 h-5 w-5"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            >
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <circle cx="12" cy="7" r="4" />
                                <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                            </svg>
                            <span>{{ 'common.profile' | transloco }}</span>
                        </button>
                        <button
                            class="!flex items-center hover:bg-gray-700"
                            mat-menu-item
                            role="menuitem"
                            [routerLink]="['/auth/forgot-password']"
                            [queryParams]="{ email: (userMail$ | async) }"
                            [mixpanel]="{
                                eventName: EVENT_NAVIGATION,
                                properties: { location: '/auth/forgot-password' }
                            }"
                        >
                            <svg
                                class="mr-2 h-5 w-5"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            >
                                <path d="M0 0h24v24H0z" stroke="none" />
                                <path
                                    d="M15 4.55a8 8 0 00-6 14.9M9 15v5H4M18.37 7.16v.01M13 19.94v.01M16.84 18.37v.01M19.37 15.1v.01M19.94 11v.01"
                                />
                            </svg>
                            <span>{{ 'auth.reset-password' | transloco }}</span>
                        </button>
                        <button
                            class="!flex items-center hover:bg-gray-700"
                            mat-menu-item
                            role="menuitem"
                            (click)="signout()"
                            [mixpanel]="{ eventName: EVENT_NAVIGATION, properties: { location: '/signout' } }"
                        >
                            <svg
                                class="mr-2 h-5 w-5"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            >
                                <path d="M0 0h24v24H0z" stroke="none" />
                                <path d="M14 8V6a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2h7a2 2 0 002-2v-2" />
                                <path d="M7 12h14l-3-3m0 6l3-3" />
                            </svg>
                            {{ 'common.logout' | transloco }}
                        </button>
                    </mat-menu>
                </ng-container></ng-container
            >
            <ng-template #placeHolder>
                <div
                    class="block h-7 w-7 cursor-pointer overflow-hidden rounded-xl border-2 border-gray-500"
                    data-testid="userLoading"
                >
                    <cgib-loading-indicator></cgib-loading-indicator>
                </div>
            </ng-template>
            <ng-template #signIn>
                <a
                    cgibLink
                    data-testid="sign-in"
                    [ariaLabel]="'Sign in'"
                    [routerLink]="'/auth/sign-in'"
                    [href]="'/auth/sign-in'"
                    routerLinkActive
                    #rla="routerLinkActive"
                    [isActive]="rla.isActive"
                    [theme]="'ai-glass-effect'"
                    [queryParams]="{ redirectUrl: currentRoute }"
                    [doNotFollow]="true"
                    overrideClass="max-w-[80px]"
                    ><div class="whitespace-nowrap">{{ 'common.sign-in' | transloco }}</div></a
                >
                <div class="hidden sm:flex">
                    <a
                        cgibLink
                        #signUp
                        [ariaLabel]="'Sign up'"
                        [routerLink]="'/auth/sign-up'"
                        [href]="'/auth/sign-up'"
                        routerLinkActive
                        #rla="routerLinkActive"
                        [isActive]="rla.isActive"
                        [queryParams]="{ redirectUrl: currentRoute }"
                        [doNotFollow]="true"
                        overrideClass="px-3 w-[80px] h-7"
                        ><div class="whitespace-nowrap">{{ 'common.sign-up' | transloco }}</div></a
                    >
                </div>
            </ng-template>
        </div>
    </div>
    @if (showReducedSearch()) {
        <cgib-search-input [searchTerm]="term()"></cgib-search-input>
    }
</header>

<div class="relative h-full w-full">
    <cgib-header-teaser *ngIf="teaser"></cgib-header-teaser>
    <cgib-header-image *ngIf="image && !showReducedSearch() && !teaser" [imageUrl]="image"></cgib-header-image>
</div>

<ng-template #menuTemplate>
    <div
        class="relative flex h-full w-full flex-col bg-blue-500 text-white"
        [@menu]="menuState"
        (@menu.done)="closeMenu($event)"
    >
        <ng-scrollbar class="ng-scrollbar">
            <div class="scroll-content p-8">
                <nav *ngVariable="navigation$ | async as navigation" class="h-full">
                    <ng-container *ngFor="let nav of navigation">
                        <ul class="mb-8 flex h-full flex-col">
                            <li class="mb-2 text-xl font-semibold uppercase">
                                <a class="">{{ nav.sectionName }}</a>
                            </li>
                            <ng-container *ngFor="let item of nav.links">
                                <li
                                    class="uppercase leading-8"
                                    *ngVariable="
                                        $any(item.link).cached_url
                                            ? '/' + $any(item.link).cached_url
                                            : $any(item.link).url as url
                                    "
                                >
                                    <a
                                        [routerLink]="[url]"
                                        class=""
                                        (click)="close()"
                                        [mixpanel]="{
                                            eventName: EVENT_NAVIGATION,
                                            properties: { location: url }
                                        }"
                                    >
                                        {{ item.displayName }}
                                    </a>
                                </li>
                            </ng-container>
                        </ul>
                    </ng-container>
                </nav>
            </div>
        </ng-scrollbar>
        <svg
            class="absolute right-2 top-2 h-10 w-10 cursor-pointer"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            (click)="close()"
        >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <line x1="18" y1="6" x2="6" y2="18" />
            <line x1="6" y1="6" x2="18" y2="18" />
        </svg>
    </div>
</ng-template>
