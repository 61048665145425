import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, Injectable, ViewContainerRef } from '@angular/core';

import { UploadFileSelectPortalComponent } from './upload-file-select-portal.component';

@Injectable({
    providedIn: 'root'
})
export class FileSelectPortalService {
    componentRef?: ComponentRef<UploadFileSelectPortalComponent>;
    overlayRef: OverlayRef | undefined;
    componentPortal: ComponentPortal<UploadFileSelectPortalComponent> | undefined;

    constructor(private readonly overlay: Overlay, private readonly viewContainerRef: ViewContainerRef) {}

    openVisualSearchInput() {
        if (!this.overlayRef) {
            this.overlayRef = this.overlay.create({
                hasBackdrop: true,
                disposeOnNavigation: true,
                backdropClass: ['backdrop-blur-md', 'bg-gray-500/20'],
                panelClass: ['flex', 'justify-center', 'overflow-auto', 'drop-shadow-3xl', 'shadow-gray-900'],
                scrollStrategy: this.overlay.scrollStrategies.block(),
                positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically()
            });

            this.componentPortal = new ComponentPortal(UploadFileSelectPortalComponent, this.viewContainerRef);

            this.componentRef = this.overlayRef.attach(this.componentPortal);
            this.overlayRef.backdropClick().subscribe(() => {
                this.componentRef?.instance.close();
            });
            this.componentRef?.instance.closeEvent.subscribe(() => {
                this.close();
            });
        }
    }
    close() {
        this.componentRef = undefined;
        this.overlayRef?.dispose();
        this.overlayRef = undefined;
    }
}
