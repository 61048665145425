import { Observable, map } from 'rxjs';
import { ISbLinkURLObject } from 'storyblok-js-client';

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { createStore, select, withProps } from '@ngneat/elf';
import { persistState, sessionStorageStrategy } from '@ngneat/elf-persist-state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MemoizedObservable } from '@cgib/shared/helper/memoized-observable';

import { StoryblokDirective } from '../../../../shared/directives/storyblok.directive';
import { StoryblokBaseComponent } from '../storyblok-base/storyblok-base.component';

interface BannerProps {
    uid: string | undefined;
    timestamp: number;
}

const bannerStore = createStore(
    { name: 'BannerService' },
    withProps<BannerProps>({
        uid: undefined,
        timestamp: 0
    })
);

persistState(bannerStore, {
    key: 'BannerService',
    storage: sessionStorageStrategy
});

@UntilDestroy()
@Component({
    selector: 'cgib-banner-header',
    imports: [CommonModule, StoryblokDirective, RouterModule],
    templateUrl: './banner-header.component.html',
    styleUrls: ['./banner-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BannerHeaderComponent extends StoryblokBaseComponent {
    @Input({ required: true }) name: string;
    @Input() description?: string;
    @Input() backgroundColor: 'BLACK' | 'MEDIUM' | 'LIGHT' | 'DARK' = 'MEDIUM';
    @Input({ required: true }) link: {
        link: { cached_url: string; type: string } | ISbLinkURLObject;
        displayName: string;
    }[];
    @Input() title?: string;
    @Input({ required: true }) endDate: string;
    @Input({ required: true }) startDate: string;

    showBanner$ = this.closedByUser$().pipe(
        map(closed => {
            const now = Date.now();
            if (closed) {
                return false;
            }
            if (Date.parse(this.endDate) > now && Date.parse(this.startDate) < now) {
                return true;
            }
            return false;
        }),
        untilDestroyed(this)
    );

    close() {
        this.setBannerState({ uid: this._uid, timestamp: Date.now() });
    }

    @MemoizedObservable()
    closedByUser$(): Observable<boolean> {
        return bannerStore.pipe(
            select(state => state.uid),
            map(uid => this._uid === uid)
        );
    }
    private setBannerState(props: Partial<BannerProps>) {
        bannerStore.update(state => ({ ...state, ...props }));
    }
}
