<h2
    class="mb-8 mt-8 inline-flex max-w-[calc(100vw-32px)] text-center text-5xl font-semibold tracking-wider text-white drop-shadow-xl md:text-6xl lg:text-7xl"
    *ngIf="title"
>
    <div class="flex justify-center text-5xl" *ngIf="title === 'CGI.Backgrounds'; else showUncoloredTitle">
        <img src="/assets/logo.svg" class="h-full w-full" />
    </div>
    <ng-template #showUncoloredTitle>
        {{ title }}
    </ng-template>
</h2>

<div
    class="group mx-8 flex w-[600px] max-w-[calc(100vw-32px)] flex-col items-center space-y-3 rounded-3xl"
    [ngClass]="{
        'border border-gray-100/10 bg-gradient-to-br from-blue-500/20 to-purple-800/20 p-2 shadow-xl backdrop-blur-sm':
            showVisualSearch()
    }"
>
    <div class="relative -ml-px w-full">
        <input
            class="block w-full rounded-2xl border-gray-100 py-[11px] pl-14 text-gray-600 focus-within:z-10 focus:border-blue-700 focus:outline-none focus:ring-1 focus:ring-blue-700"
            #term
            data-testid="search-term"
            type="text"
            autocomplete="off"
            [placeholder]="'common.search' | transloco"
            (keydown.enter)="search(term.value); term.blur()"
            aria-label="Search Input"
        />
        <button
            class="absolute left-1 top-1 cursor-pointer rounded-xl bg-blue-700 p-2 text-white hover:bg-blue-600"
            aria-label="Search"
            (click)="search(term.value); term.blur()"
        >
            <svg
                class="h-6 w-6"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
            >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <circle cx="10" cy="10" r="7" />
                <line x1="21" y1="21" x2="15" y2="15" />
            </svg>
        </button>

        <!-- <mat-select
            (selectionChange)="searchType.set(typeSelect.value)"
            #typeSelect
            data-testid="type-select"
            [value]="searchType()"
            class="absolute right-1 top-1 max-w-44"
        >
            @for (opt of ['locations', 'hdri-maps', 'scenes', '3d-backgrounds']; track opt) {
                <mat-option [value]="opt">{{ 'common.' + opt | transloco }}</mat-option>
            }
        </mat-select> -->

        <!--<button
                class="absolute right-1 top-1 cursor-pointer rounded-xl bg-gradient-to-br from-blue-500 to-purple-800"
                aria-label="Search"
                (click)="openVisualSearchInput()"
            >
                <div class="border border-white/10 bg-white/20 p-[7px] hover:bg-white/10">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        *ngIf="(loading$ | async) === false; else showLoading"
                    >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path
                            d="M11.5 20h-6.5a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2h1a2 2 0 0 0 2 -2a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1a2 2 0 0 0 2 2h1a2 2 0 0 1 2 2v2.5"
                        ></path>
                        <path d="M14.757 11.815a3 3 0 1 0 -3.431 4.109"></path>
                        <path d="M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
                        <path d="M20.2 20.2l1.8 1.8"></path>
                    </svg>
                    <ng-template #showLoading>
                        <cgib-loading-indicator class="h-6 w-6 text-white"></cgib-loading-indicator
                    ></ng-template>
                </div>
            </button> -->
    </div>
    @if (showVisualSearch()) {
        <cgib-upload-file-select
            class="w-full"
            [prefix]="''"
            [bucket]="''"
            [prefix]="'visual-search'"
            [showSearchButton]="false"
        ></cgib-upload-file-select>
    }
</div>
<h3
    class="mx-8 my-12 hidden max-w-[calc(100vw-32px)] px-4 py-2 text-center text-2xl text-gray-100 sm:inline-flex"
    *ngIf="subtitle"
>
    {{ subtitle }}
</h3>
