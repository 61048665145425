import { Pipe, PipeTransform } from '@angular/core';
import { AssetTypeEnum } from '@cgib/shared/enums/asset-type.enum';

import { lightboxItemPick } from '../../types/lightbox-item-pick.type';

@Pipe({
    name: 'lightboxItemFilter',
    standalone: false
})
export class LightboxFilterPipe implements PipeTransform {
    transform(
        ligthboxItems: lightboxItemPick[] | null | undefined,
        filterType: string | undefined
    ): lightboxItemPick[] | null | undefined {
        if (!filterType || filterType === 'COMBINED') {
            return ligthboxItems;
        }
        if (filterType === AssetTypeEnum.DOME || filterType === AssetTypeEnum.IMAGE) {
            return ligthboxItems?.filter(item =>
                filterType === AssetTypeEnum.DOME
                    ? item.name.match(/cgib_[A-Z]{3}_[0-9]{6}_(HDR_[0-9]{2})_[0-9]{8}/)
                    : item.name.match(/cgib_[A-Z]{3}_[0-9]{6}_(S_[A-Z][0-9]{2})_[0-9]{8}/)
            );
        }
        return ligthboxItems?.filter(item => item.type === filterType);
    }
}
