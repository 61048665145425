import { Injectable, inject, signal } from '@angular/core';
import { Router } from '@angular/router';

import { SearchTypes } from '@cgib/shared/types/search-types';

@Injectable({
    providedIn: 'root'
})
export class SearchInputService {
    private readonly router = inject(Router);
    searchType = signal<SearchTypes>('locations');

    setSearchTerm(term: string) {
        let type: SearchTypes | undefined = this.searchType();
        console.log(term.match(/S[0-9]{5}/));
        if (term.toLocaleLowerCase().includes('scene')) {
            term = term
                .replaceAll('scenes', '')
                .replaceAll('Scenes', '')
                .replaceAll('scene', '')
                .replaceAll('Scene', '');
            type = 'scenes';
        } else if (['visual-hdri-search', 'visual-search'].includes(this.searchType() ?? 'locations')) {
            type = 'locations';
        } else if (term.match(/S[0-9]{5}/)) {
            type = 'scenes';
        } else if (term.match(/B[0-9]{5}/)) {
            type = '3d-backgrounds';
        } else if (term.match(/[0-9]{6}/)) {
            type = 'locations';
        }
        console.log('Navgate to search term');
        this.router.navigate(['/search'], {
            queryParams: {
                term,
                page: 1,
                type
            },
            queryParamsHandling: ''
        });
    }
}
