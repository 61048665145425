import { BehaviorSubject } from 'rxjs';

import { AnimationEvent, animate, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Output,
    signal
} from '@angular/core';
import { Router } from '@angular/router';

import { MixpanelEventEnum } from '@cgib/shared/enums/mixpanel-event.enum';
import { ButtonDirective } from '@cgib/ui-shared/directives/button.directive';
import { ButtonTheme } from '@cgib/ui-shared/enums/button-theme.enum';
import { IndicatorsModule } from '@cgib/ui-shared/modules/indicators/indicators.module';
import { MixpanelModule } from '@cgib/ui-shared/modules/mixpanel/mixpanel.module';

import { UploadFileSelectComponent } from '../upload-file-select/upload-file-select.component';

@Component({
    selector: 'cgib-upload-file-select-portal',
    imports: [CommonModule, UploadFileSelectComponent, MixpanelModule, IndicatorsModule, ButtonDirective],
    templateUrl: './upload-file-select-portal.component.html',
    styleUrls: ['./upload-file-select-portal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('details', [
            state('void', style({ opacity: 0 })),
            state('enter', style({ opacity: 1 })),
            state('leave', style({ opacity: 0 })),
            transition('* => *', animate('0.3s ease-in-out'))
        ])
    ]
})
export class UploadFileSelectPortalComponent {
    MIXPAEL = MixpanelEventEnum;

    THEME = ButtonTheme;

    overlayState = 'enter';
    @Output() closeEvent = new EventEmitter<void>();

    fileUrls = signal<string[]>([]);
    loading$ = new BehaviorSubject<boolean>(false);
    searchEnabled$ = new BehaviorSubject<boolean>(false);
    constructor(
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly router: Router
    ) {}
    @HostListener('document:keydown.escape')
    escCloseOverlay() {
        this.close();
    }
    close() {
        this.overlayState = 'leave';
        this.changeDetectorRef.markForCheck();
        this.closeEvent.emit();
    }

    closeOverlay(event: AnimationEvent) {
        if (event.toState === 'leave') {
            this.closeEvent.emit();
        }
    }
    enableSearchButtons(url: string[]) {
        this.fileUrls.set(url);
        this.searchEnabled$.next(true);
        this.close();
        this.router.navigate(['search'], {
            queryParams: { 'image-url': this.fileUrls()[0], page: 1, term: '', type: 'visual-location-search' },
            queryParamsHandling: 'merge'
        });
    }
}
