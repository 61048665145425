import { NgScrollbarModule } from 'ngx-scrollbar';
import { BehaviorSubject, filter, lastValueFrom, tap } from 'rxjs';

import { AnimationEvent, animate, state, style, transition, trigger } from '@angular/animations';
import { GlobalPositionStrategy, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    TemplateRef,
    ViewContainerRef,
    inject,
    input
} from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { Router, RouterModule } from '@angular/router';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { BannerItem, NavigationItem } from '@cgib/shared/dtos/storyblok/navigation-page.dto';
import { MixpanelEventEnum } from '@cgib/shared/enums/mixpanel-event.enum';
import { UserPermissionEnum } from '@cgib/shared/enums/user-permission.enum';
import { HostHideDirective } from '@cgib/ui-shared/directives/host-hide.directive';
import { LinkDirective } from '@cgib/ui-shared/directives/link.directive';
import { NgVariableDirective } from '@cgib/ui-shared/directives/ng-variable.directive';
import { IndicatorsModule } from '@cgib/ui-shared/modules/indicators/indicators.module';
import { MixpanelModule } from '@cgib/ui-shared/modules/mixpanel/mixpanel.module';
import { AuthService } from '@cgib/ui-shared/services/auth.service';
import { LightboxGraphqlService } from '@cgib/ui-shared/services/graphql/graphql-lightbox.service';
import { UserService } from '@cgib/ui-shared/services/user.service';

import { SupportedLanguages } from '../../shared/interfaces/languages.enum';
import { SessionService } from '../../shared/services/session/session.service';
import { StoryblokService } from '../../shared/services/storyblok/storyblok.service';
import { SearchInputComponent } from '../search-input/search-input.component';
import { BannerHeaderComponent } from '../storyblok/nestables/banner-header/banner-header.component';
import { HeaderImageComponent } from './header-image/header-image.component';
import { HeaderTeaserComponent } from './header-teaser/header-teaser.component';

@UntilDestroy()
@Component({
    imports: [
        CommonModule,
        HeaderTeaserComponent,
        HeaderImageComponent,
        MixpanelModule,
        RouterModule,
        MatMenuModule,
        TranslocoModule,
        NgVariableDirective,
        BannerHeaderComponent,
        IndicatorsModule,
        NgScrollbarModule,
        LinkDirective,
        HostHideDirective,
        SearchInputComponent
    ],
    selector: 'cgib-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('menu', [
            state('void', style({ transform: 'translateX(-100%)' })),
            state('enter', style({ transform: 'translateX(0)' })),
            state('leave', style({ transform: 'translateX(-100%)' })),
            transition('* => *', animate('0.3s ease-in-out'))
        ])
    ]
})
export class HeaderComponent {
    private readonly userService = inject(UserService);
    private readonly lightboxService = inject(LightboxGraphqlService);
    EVENT_NAVIGATION = MixpanelEventEnum.NAVIGATION;
    USER_PERMISSION = UserPermissionEnum;

    isAuthenticated$ = this.userService.isAuthenticated$();
    userMail$ = this.userService.getUserEmail$().pipe(filter(data => !!data));
    gravatarUrl$ = this.userService.getGravatarUrl$(120).pipe(filter(data => !!data));
    menuState = 'enter';
    currentRoute = this.router.url;
    languages = Object.values(SupportedLanguages);
    activeLanguage$ = this.transloco.langChanges$;
    overlayRef: OverlayRef | undefined;
    navigation$ = new BehaviorSubject<NavigationItem[]>([]);
    banner$ = new BehaviorSubject<BannerItem[]>([]);
    @Input() slider = false;
    @Input() teaser = false;
    @Input() image: string | null = null;
    term = input.required<string>();
    showReducedSearch = input.required<boolean>();

    constructor(
        private readonly overlay: Overlay,
        private readonly authService: AuthService,
        private readonly router: Router,
        private readonly transloco: TranslocoService,
        private readonly sessionService: SessionService,
        private readonly viewContainerRef: ViewContainerRef,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly storyblokService: StoryblokService
    ) {
        this.userMail$.pipe(untilDestroyed(this)).subscribe();
        this.gravatarUrl$.pipe(untilDestroyed(this)).subscribe();
        this.isAuthenticated$.pipe(untilDestroyed(this)).subscribe();
        this.storyblokService
            .getNavigations$('home')
            .pipe(
                tap(data => this.navigation$.next(data.content.header)),
                tap(data => this.banner$.next(data.content.banner))
            )
            .subscribe();
    }

    openMenu(menuTemplateRef: TemplateRef<unknown>) {
        this.menuState = 'enter';
        this.overlayRef = this.overlay.create({
            hasBackdrop: true,
            backdropClass: ['backdrop-blur-md', 'bg-gray-900/[.4]'],
            positionStrategy: new GlobalPositionStrategy(),
            scrollStrategy: this.overlay.scrollStrategies.block(),
            width: '100%',
            maxWidth: '320px'
        });

        const menuTemplatePortal = new TemplatePortal(menuTemplateRef, this.viewContainerRef);

        this.overlayRef.attach(menuTemplatePortal);
        this.overlayRef.backdropClick().subscribe(() => this.close());
    }

    changeLanguage(language: SupportedLanguages) {
        this.sessionService.setLanguage(language);
        this.transloco.setActiveLang(language);
    }

    close() {
        this.menuState = 'leave';
        this.changeDetectorRef.markForCheck();
        setTimeout(() => {
            this.overlayRef && this.overlayRef.dispose();
        }, 400);
    }

    closeMenu(event: AnimationEvent) {
        if (event.toState === 'leave' && this.overlayRef) {
            this.overlayRef.dispose();
            this.overlayRef = void 0;
        }
    }

    async signout() {
        await lastValueFrom(this.authService.signOut$());
        this.lightboxService.evictQueries([
            { id: 'ROOT_QUERY', fieldName: 'getPaginatedLightboxDetails' },
            { id: 'ROOT_QUERY', fieldName: 'getLightboxItemIds' },
            { id: 'ROOT_QUERY', fieldName: 'listLightboxes' },
            { id: 'ROOT_QUERY', fieldName: 'getLightboxAllItems' },
            { id: 'ROOT_QUERY', fieldName: 'listPartialLightboxes' },
            { id: 'ROOT_QUERY', fieldName: 'getUserDetails' },
            { id: 'ROOT_QUERY', fieldName: 'getQuota' },
            { id: 'ROOT_QUERY', fieldName: 'getTransactionHistory' },
            { id: 'ROOT_QUERY', fieldName: 'listCloudLibraryItems' }
        ]);

        this.router.navigate(['/home']);
    }
}
