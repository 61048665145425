import { BehaviorSubject } from 'rxjs';

import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, Input, input, signal } from '@angular/core';
import { MatSelectModule } from '@angular/material/select';
import { Router } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { UntilDestroy } from '@ngneat/until-destroy';

import { AssetTypeEnum } from '@cgib/shared/enums/asset-type.enum';
import { MixpanelEventEnum } from '@cgib/shared/enums/mixpanel-event.enum';
import { IndicatorsModule } from '@cgib/ui-shared/modules/indicators/indicators.module';
import { MixpanelService } from '@cgib/ui-shared/services/mixpanel.service';

import { FileSelectPortalService } from '../../upload-file-select-portal/file-select-portal.service';
import { UploadFileSelectComponent } from '../../upload-file-select/upload-file-select.component';

@UntilDestroy()
@Component({
    imports: [CommonModule, TranslocoModule, IndicatorsModule, UploadFileSelectComponent, MatSelectModule],
    selector: 'cgib-header-search',
    templateUrl: './header-search.component.html',
    styleUrls: ['./header-search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [FileSelectPortalService]
})
export class HeaderSearchComponent implements AfterViewInit {
    @Input() title: string | null = null;
    @Input() subtitle: string | null = null;

    showVisualSearch = input.required<boolean>();

    loading$ = new BehaviorSubject<boolean>(false);
    searchTypes = Object.values(AssetTypeEnum).map(k => k.toLowerCase());
    searchType = signal<string>('');
    constructor(
        private readonly router: Router,
        private readonly mixpanelService: MixpanelService
    ) {}

    ngAfterViewInit() {
        setTimeout(() => {
            this.searchType.set('locations'); // Without the mat select does not render the option
        }, 100);
    }

    search(searchTerm: string) {
        this.mixpanelService.track(MixpanelEventEnum.NAVIGATION, { location: `/search?term=${searchTerm}&page=1` });
        this.router.navigate(['search'], {
            queryParams: {
                term: searchTerm,
                page: 1,
                type: this.searchType()
            }
        });
    }

    // openVisualSearchInput() {
    //     this.fileSelectServicePortal.openVisualSearchInput();
    // }
}
