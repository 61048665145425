export interface Contact {
    color: string;
    location: string;
    phone: string;
    email: string;
}

export const contacts = [
    {
        color: '#28a8ec',
        location: 'USA',
        email: 'contact@cgibackgrounds.com'
    },
    // {
    //     color: '#28a8ec',
    //     location: 'USA / Los Angeles',
    //     phone: '+ 1 (866) 303 0733',
    //     email: 'contact@cgibackgrounds.com'
    // },
    {
        color: '#008000',
        location: 'Germany',
        email: 'germany@cgibackgrounds.com'
    },
    {
        color: '#ffd600',
        location: 'China',
        email: 'china@cgibackgrounds.com '
    },
    {
        color: '#f90c09',
        location: 'Japan',
        email: 'japan@cgibackgrounds.com'
    }
];
