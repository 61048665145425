<div
    class="relative flex h-full min-h-[200px] w-[400px] flex-col overflow-hidden rounded-3xl bg-gradient-to-br from-blue-500/50 to-purple-800/50"
    [@details]="overlayState"
    (@details.done)="closeOverlay($event)"
>
    <div class="w-full p-4">AI Powered Visual Search</div>
    <button
        cgibButton
        [ariaLabel]="'Close Popup'"
        (click)="close()"
        overrideClass="absolute right-3 top-3 z-20 aspect-square h-7 w-7"
        [theme]="THEME.aiGlassEffect"
    >
        <svg
            class="h-5 w-5 text-white"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            a
            stroke-linejoin="round"
        >
            <path d="M0 0h24v24H0z" stroke="none" />
            <path d="M18 6L6 18M6 6l12 12" />
        </svg>
    </button>

    <div class="grid grid-cols-1 gap-3 p-4 pt-0">
        <cgib-upload-file-select
            (finishUpload)="enableSearchButtons($event)"
            [bucket]="''"
            [prefix]="'visual-search'"
            [showSearchButton]="false"
        ></cgib-upload-file-select>
        <!-- @let urls = fileUrls();
        <div class="text-xs">
            <img [src]="urls[0]" *ngIf="urls && urls.length; else showEmpty" class="h-full w-full object-contain" />
            <ng-template #showEmpty>
                <div class="flex h-full w-full items-center justify-center bg-gray-200/20">Your Image</div>
            </ng-template>
        </div> -->
    </div>

    <!-- <div class="flex w-full flex-row space-x-3 p-4">
        <div class="col-span-2 flex w-full flex-col space-y-2">
            <button
                cgibButton
                [theme]="THEME.aiGlassEffect"
                [mixpanelEvent]="MIXPAEL.VISUAL_SEARCH_HDRI"
                [mixpanelProperties]="{ origin: 'visual-search' }"
                (click)="search('visual-search')"
                ariaLabel="Search similar HDRi Images"
                [disabled]="(searchEnabled$ | async) !== true"
            >
                <div *ngIf="(loading$ | async) === false" class="whitespace-nowrap text-sm md:text-base">
                    Search Similar Backplates
                </div>
                <div *ngIf="loading$ | async" class="flex space-x-3">
                    <cgib-loading-indicator class="h-6 w-6"> </cgib-loading-indicator>
                    <div>Searching...</div>
                </div>
            </button>
            <button
                cgibButton
                [theme]="THEME.aiGlassEffect"
                (click)="search('visual-hdri-search')"
                [disabled]="(searchEnabled$ | async) !== true"
                ariaLabel="Search similar Backplate Images"
                [mixpanelEvent]="MIXPAEL.VISUAL_SEARCH_BACKPLATES"
                [mixpanelProperties]="{ origin: 'visual-hdri-search' }"
            >
                <div *ngIf="(loading$ | async) === false" class="whitespace-nowrap text-sm md:text-base">
                    Search Similar HDRis
                </div>
                <div *ngIf="loading$ | async" class="flex space-x-3">
                    <cgib-loading-indicator class="h-6 w-6"> </cgib-loading-indicator>
                    <div>Searching...</div>
                </div>
            </button>
        </div>
    </div> -->
</div>
