import { NgZone } from '@angular/core';

import { CookiebotService } from './cookiebot.service';

/**
 *
 */
export function cookiebotFactory(cookieBotService: CookiebotService, zone: NgZone): () => void {
    return (): void => {
        return cookieBotService.init(zone);
    };
}
