import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { HeaderSearchComponent } from '../header-search/header-search.component';

@Component({
    imports: [CommonModule, HeaderSearchComponent],
    selector: 'cgib-header-image',
    templateUrl: './header-image.component.html',
    styleUrls: ['./header-image.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderImageComponent {
    @Input() imageUrl = 'assets/images/header.jpg';
}
