import { BehaviorSubject, tap } from 'rxjs';

import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { NavigationItem } from '@cgib/shared/dtos/storyblok/navigation-page.dto';
import { MixpanelEventEnum } from '@cgib/shared/enums/mixpanel-event.enum';
import { LinkDirective } from '@cgib/ui-shared/directives/link.directive';
import { NgVariableDirective } from '@cgib/ui-shared/directives/ng-variable.directive';
import { MixpanelModule } from '@cgib/ui-shared/modules/mixpanel/mixpanel.module';

import { StoryblokService } from '../../shared/services/storyblok/storyblok.service';
import { contacts } from './contacts';

@UntilDestroy()
@Component({
    imports: [CommonModule, NgVariableDirective, MixpanelModule, RouterModule, LinkDirective],
    selector: 'cgib-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
    EVENT_NAVIGATION = MixpanelEventEnum.NAVIGATION;
    contacts = contacts;
    loading$ = new BehaviorSubject<boolean>(false);

    navigation$ = new BehaviorSubject<NavigationItem[]>([]);
    constructor(private readonly storyblokService: StoryblokService) {
        this.storyblokService
            .getNavigations$('home')
            .pipe(
                tap(data => this.navigation$.next(data.content.footer)),
                untilDestroyed(this)
            )
            .subscribe();
    }
    getCurrentYear(): string {
        return new Date().getFullYear().toString();
    }
}
