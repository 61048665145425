<div class="relative h-full w-full" #videoContainer>
    @if (isMobileHeader()) {
        <img
            class="absolute h-full w-full object-cover"
            [priority]="true"
            [ngSrc]="'assets/images/shared/video.jpg'"
            [fill]="true"
        />
    }
    @if (!isMobileHeader()) {
        <video
            class="absolute h-full w-full object-cover"
            [src]=""
            muted="true"
            autoplay="true"
            loop="true"
            preload="auto"
            playsinline
            [playbackRate]="0.75"
            [poster]="'assets/images/shared/video.jpg'"
        ></video>
    }

    <div class="absolute h-full w-full bg-black/60 saturate-150"></div>
    <cgib-header-search [title]="null" [subtitle]="null" [showVisualSearch]="true"></cgib-header-search>
</div>
