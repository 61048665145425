import { asyncScheduler, fromEvent, throttleTime } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostBinding,
    Input,
    afterNextRender
} from '@angular/core';
import { MixpanelEventEnum } from '@cgib/shared/enums/mixpanel-event.enum';
import { MixpanelModule } from '@cgib/ui-shared/modules/mixpanel/mixpanel.module';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'cgib-scroll-top',
    templateUrl: './scroll-top.component.html',
    styleUrls: ['./scroll-top.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule, MixpanelModule]
})
export class ScrollTopComponent {
    EVENT_NAVIGATION = MixpanelEventEnum.NAVIGATION;

    @Input()
    @HostBinding('style.bottom.px')
    public bottom = 0;

    @Input()
    @HostBinding('style.left.px')
    public left = 0;

    @HostBinding('style.opacity')
    private opacity = 0;

    @HostBinding('style.pointer-events')
    private pointerEvents = 'none';

    constructor(private readonly changeDetectorRef: ChangeDetectorRef) {
        afterNextRender(() => {
            fromEvent(window, 'scroll')
                .pipe(throttleTime(100, asyncScheduler, { leading: false, trailing: true }), untilDestroyed(this))
                .subscribe(() => {
                    if (window.scrollY > 300) {
                        this.opacity = 1;
                        this.pointerEvents = 'all';
                    } else {
                        this.opacity = 0;
                        this.pointerEvents = 'none';
                    }
                    this.changeDetectorRef.markForCheck();
                });
        });
    }

    scrollTop() {
        window.scroll({ top: 0, behavior: 'smooth' });
    }
}
